<template>
  <div class="container">
    <div class="banner" v-loading="loading">
      <img :src="banner_url ? banner_url : ''" />
      <div class="music-title">
        <p>
          <span>音乐列表<label>({{ num }})</label></span>
          <a @click="openVip">开通VIP单条素材低至0.3元 ></a>
        </p>
      </div>
    </div>
    <!-- 音乐列表 -->
    <section id="section">
      <div class="music-cont">
        <div class="music-list" v-for="(item,index) in musicList" :key="index" :class="{actived: musicIndex == index }">
          <!-- 音乐封面 -->
          <div class="music-cover"
               :style="{backgroundImage: 'url('+(item.logo == '' ? require('@/assets/image/mobile/music_cover.jpg') : item.logo)+')'}"
               @click="musicIndex = index,handlePlayerMusic(item,index)">
            <img src="@/assets/image/mobile/audio_pian.png" class="cover">
            <div class="mask">
<!--              v-if="setPlaystatus(item)"-->
              <ul class="pause" v-if="state && (Info && (Info.object_id == item.object_id))">
                <li class="beat"></li>
                <li class="beat"></li>
                <li class="beat"></li>
                <li class="beat"></li>
              </ul>
              <img src="~@/assets/svg/pause-audio-icon.svg" class="play" v-else>
            </div>
          </div>
          <!-- 音乐信息 -->
          <div class="music-info">
            <div class="music-desc" @click="musicIndex = index,handlePlayerMusic(item,index)">
              <p class="music-name">{{ item.name }}</p>
              <div class="tag">
                <span v-for="(ele,ind) in item.label.slice(0,3)" :key="ind">
                  {{ ele.name }},
                </span>
                <!-- {{ item.label.slice(0,3).map((ele)=>{
                  return ele.name
                }).join(', ')}} -->
              </div>
            </div>
            <div class="music-btn">
<!--              <img src="@/assets/image/mobile/m_download_icon.png" class="download" @click="handleDownload()">-->
              <img src="@/assets/image/mobile/m_download_icon.png" class="download" @click="downloadFile(item)">
            </div>
          </div>

        </div>
      </div>
    </section>

    <footer class="audio_cont" v-if="false">
      <!-- 音乐封面 -->
      <div class="audio_cover" @click="handleplayStatus">
        <el-image :src="musicInfo.logo ? musicInfo.logo : cover_url"
                  style="width:.85rem;height:.85rem"
                  fit="fill">
        </el-image>
        <div class="cover_cont">
          <img
            :src="
            require(`@/assets/svg/${
              playing ? 'play' : 'pause'
            }-audio-icon.svg`)
          "
          />
        </div>
      </div>
      <!-- 音乐信息 -->
      <div class="audio_info">
        <div class="audio_name">
          {{ musicInfo.name }}
        </div>
        <div class="audio_slider">
          <div class="block">
            <el-slider
              v-model="progress"
              :show-tooltip="false"
              :min="0"
              :step="0.1"
              :max="Number(musicInfo.duration) || 0"
              @change="handleProgressChange"
            />
          </div>
          <div class="duration_info">
            {{ progress | formatTimeBySecond }}/{{ musicInfo.duration | formatTimeBySecond }}
          </div>
        </div>
      </div>
    </footer>

    <PlayBar v-if='Info' :musicInfo="Info" ref='audioRef' @updateStatus="updateStatus"></PlayBar>
    <!--下载提升弹框-->
    <dialogBox :packageObj='packageObj' :visible='visibleDialog' v-if='visibleDialog'></dialogBox>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import { getPlaylist } from "@/api/mobile";
import { globalAudion } from "@/config";
import { getTempDeviceId, getToken } from "@/utils/author";
import { fetchPlayTimes } from "@/api/music";
import Buttons from "@/layout/mixins/Buttons";
import Common from './mixins/Common';
import dialogBox from "./components/DialigBox"
import PlayBar from './components/PlayBar'

export default {
  components:{
    dialogBox,
    PlayBar
  },
  data() {
    return {
      state: false,
      Info: null,
      num: 0,
      showDialog: false,
      playlist_id: "", // 歌单ID
      banner_url: "",
      musicIndex: -1, // 当前音频下标
      cover_url: require("@/assets/image/mobile/music_cover.jpg"),
      // progress: 0, // 音乐进度
      audioContent: null,
      musicList: [], // 音乐列表
      timer: null,
      currentMusicid: "",
      loading: true
    };
  },
  computed: {
    // ...mapState({
    //   banner_id: (state)=> state.mobile.banner_id
    // })
    progress: {
      get() {
        return this.$store.state.mobile.currentTime;
      },
      set(newvalue) {
        return newvalue;
      }
    },
    musicInfo() {
      return this.$store.state.mobile.musicInfo;
    },
    musicId() {
      return this.$store.state.mobile?.musicInfo?.music_id || null;
    },
    // 当前音频播放状态
    playing() {
      return this.$store.state.mobile.isPlay;
    }
  },
  watch: {
    musicId() {
      if (this.playing) {
        this.pauses();
        this.audioContent.src = this.musicInfo ? this.musicInfo.music_src : "";
        this.audioContent.load();
        this.plays();
      } else {
        this.audioContent.src = this.musicInfo ? this.musicInfo.music_src : "";
        this.audioContent.load();
      }
    },
    playing() {
      if (this.playing) {
        this.plays();
      } else {
        this.pauses();
      }
    }
  },
  created() {
    this.playlist_id = this.GetQueryString("playlist_id");
    this.banner_id = window.localStorage.getItem("banner_id");
    this.getPlaydata();
    console.log(this.playlist_id, "歌单ID", this.banner_id, "banner");
    this.initAudio();
  },
  beforeRouteLeave(to, from, next) {
    console.log(from);
    if (from.path == "/mobile/music") {
      this.pauses();
    }
    next();
  },
  mixins: [Buttons,Common],
  methods: {
    updateStatus(e){
      this.state = e;
    },
    /**
     * 获取歌单列表
     */
    async getPlaydata() {
      const { banner_id, playlist_id } = this;
      const params = {
        banner_id,
        playlist_id
      };
      const res = await getPlaylist(params);
      if (res.code == 0) {
        this.loading = false;
        this.banner_url = res.data.banner;
        this.musicList = res.data.list;
        // console.log(this.musicList)
        this.num = res.data.list.length;
      }
    },
    /**
     * 获取地址栏参数
     */
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null)
        return decodeURI(r[2]);
      return null;
    },
    async handleCheckedTimes(type, object_id) {
      const vm = this;
      return new Promise((resolve) => {
        // 添加限制播放次数拦截
        if (globalAudion) {
          const tempDeviceId = getTempDeviceId();
          const params = {
            device_id: tempDeviceId, // 设备唯一码
            type: type == 1 ? 2 : 1 // 1音乐音效视频 2ai语音
          };
          if (Number(type) !== 1) {
            params.object_id = object_id;
          }
          fetchPlayTimes(params).then(res => {
            const { code, data: { data } } = res;
            if (code !== 0 || !data) {
              const token = getToken();
              // 如果未登录用户试听则进入登录页面
              if (!token) {
                vm.$store.dispatch("user/openLogin");
                resolve(false);
                return;
              }

              if (!vm.$store.state.user?.userInfo?.vip_info?.is_vip) {
                // 如果试听30次后无会员再次点击播放跳转至开通会员列表
                vm.$router.push(`/mobile/member`);
                resolve(false);
                return;
              }
              resolve(true);
              return;
            }

            resolve(true);
          });
        } else {
          resolve(true);
        }
      });
    },
    async handlePlayerMusic(item){
      if (item.type !== 1) {
        const musicInfo = this.musicInfo;
        if (!musicInfo || !(musicInfo && musicInfo.object_id === item.object_id)) {
          const res = await this.handleCheckedTimes(item.type, item.object_id);
          if (!res) return;
        }
      }
      this.Info = item;
    },
    /**
     * 播放音乐
     */
    async handlePlayerMusicOld(item) {
      if (item.type !== 1) {
        const musicInfo = this.musicInfo;
        if (!musicInfo || !(musicInfo && musicInfo.object_id === item.object_id)) {
          const res = await this.handleCheckedTimes(item.type, item.object_id);
          if (!res) return;
        }
      }
      this.$store.dispatch("mobile/updateCurrentMusic", {
        name: item.name,
        music_src: item.watermark_file,
        music_id: item.music_id,
        duration: item.duration,
        logo: item.logo
      });
      this.$store.dispatch("mobile/play");
    },
    /**
     * 关闭下载提示弹窗
     */
    closeDialog(e) {
      if (this.$refs.wrapper) {
        const isSelf = this.$refs.guide.contains(e.target);
        if (!isSelf) {
          this.showDialog = false;
        }
      }
    },
    /**
     * 设置播放按钮状态
     */
    setPlaystatus(item) {
      if (item.music_id === this.musicId) {
        return this.playing;
      } else {
        return false;
      }
    },
    /**
     * 开通会员
     */
    openVip() {
      this.$router.push({ path: "/mobile/member" });
    },
    /**
     * 点击暂停/播放音乐
     */
    handleplayStatus() {
      // console.log(this.musicId)
      if (this.audioContent.src !== "") {
        this.$store.dispatch("mobile/updatePlaystatus", !this.playing);
      }
    },
    /**
     * 拖拽进度条更新当前播放进度
     */
    handleProgressChange() {
      console.log(this.progress);
      if (this.audioContent) {
        this.$store.dispatch("mobile/updateCurrentTime", this.progress);
      }
    },
    /**
     * 下载
     */
    handleDownload() {
      this.showDialog = true;
    },
    /**
     * 初始化audio播放器
     */
    initAudio() {
      if (!this.audioContent) {
        this.audioContent = new Audio();
      }
      // this.audioContent.volumn = 1;
      this.audioContent.addEventListener("play", this.onPlay);
      this.audioContent.addEventListener("pause", this.onPause);
      this.audioContent.addEventListener("ended", this.onEnded);
      this.audioContent.addEventListener("error", this.onError);
    },
    /**
     * 播放
     */
    plays() {
      let audioContent = this.audioContent;
      if (!audioContent) {
        this.replay();
      }
      try {
        typeof audioContent.play === "function" &&
        audioContent.play().catch(() => {
          this.pauses();
        });
        this.$store.dispatch("mobile/play");
        this.currentTimeForAudio();
      } catch (error) {
        this.onError(error);
      }
    },
    /**
     * 暂停
     */
    pauses() {
      // console.log('暂停')
      const { audioContent } = this;
      this.getCurTime();
      this.clearTimer();
      audioContent.pause && audioContent.pause();
      this.$store.dispatch("mobile/pause");
    },
    /***
     * 计算总体时间
     */
    currentTimeForAudio() {
      this.getCurTime();
      if (!this.playing) {
        this.clearTimer();
        return;
      }
      this.timer = setTimeout(() => {
        this.currentTimeForAudio();
      }, 100);
    },
    /**
     * 获取当前的音频进度
     */
    getCurTime() {
      this.$store.dispatch("mobile/updateCurrentTime", this.audioContent?.currentTime || 0);
      // this.progress = this.audioContent?.currentTime || 0
    },
    /**
     * 清除定时器
     */
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    /**
     * 重新播放
     */
    replay() {
      if (this.audioContent) {
        this.pauses();
        this.$store.dispatch("mobile/pause");
        this.audioContent.currentTime = 0;
        this.progress = 0;
      }
      setTimeout(() => {
        this.plays();
      }, 10);
    },
    /**
     * 监听音乐播放
     */
    onPlay() {
      // console.log('播放')
      this.$store.dispatch("mobile/play");
    },
    /**
     * 监听音乐暂停
     */
    onPause() {
      this.$store.dispatch("mobile/pause");
    },
    /**
     * 监听音乐结束
     */
    onEnded() {
      this.$store.dispatch("mobile/pause");
      this.$store.dispatch("mobile/updateCurrentMusic", {
        name: "",
        music_src: "",
        music_id: "",
        duration: "",
        logo: ""
      });
      this.getCurTime();
      this.clearTimer();
    },
    // 播放错误
    onError() {
      this.audioContent.currentTime = 0;
      this.clearTimer();
    }
  },
  beforeDestroy() {
    this.Info = null;
    if(this.$refs.audioRef){
      if(this.$refs.audioRef.playRef){
        this.$refs.audioRef.playRef.pause();
      }
      this.$refs.audioRef.playRef = null;
      this.$refs.audioRef.playing = false;
      this.$refs.audioRef.currentTime = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/music.scss';
.container #section{
  padding-bottom: 0;
}
</style>
